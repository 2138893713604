import {Fragment, useEffect, useState} from 'react';
import Link from 'next/link';
import {Popover, Transition} from '@headlessui/react';
import {usePrivy} from '@privy-io/react-auth';
import {EnvelopeIcon} from '@heroicons/react/20/solid';

import web3Client from '@/utils/white-rabbit/contracts/WRWeb3Client';
import {formatToTinyAddress} from '@/utils/wallet/formatAddress';
import toaster from '@/utils/toaster';
import {DiscordIcon, TwitterIcon} from '@/components/SocialIcons';
import PopoverButtonV2 from '@/components/redesign/PopoverButtonV2';
import ButtonV2, {
  ButtonCategory,
  ButtonSize,
} from '@/components/redesign/ButtonV2';
import {SpinnerIcon} from '@/components/white-rabbit/minting/Icons';
import {
  useTempSession,
  useTempSessionActions,
} from '@/utils/auth/TempSessionProvider';

type Surface = 'default' | 'shop';

const LoginDropdown = ({onLogout}: {onLogout: () => void}) => {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Popover.Panel className="absolute z-10 mt-2">
        {({close}) => (
          <div className="relative grid rounded-md border border-gray-300 border-opacity-10 shadow-lg">
            <Link href={'/profile'} legacyBehavior>
              <a
                className="flex items-center bg-gray-900 bg-opacity-50 p-3 text-sm text-gray-100
                  first:rounded-t-md last:rounded-b-md hover:bg-opacity-90 hover:text-white"
              >
                Profile
              </a>
            </Link>
            <button
              className="flex items-center bg-gray-900 bg-opacity-50 p-3 text-sm text-gray-100
                first:rounded-t-md last:rounded-b-md hover:bg-opacity-90 hover:text-white"
              onClick={() => {
                onLogout();
                close();
              }}
            >
              Logout
            </button>
          </div>
        )}
      </Popover.Panel>
    </Transition>
  );
};

const NavBarLoginButton = ({
  onClick,
  size = 'sm',
  category = 'translucent',
}: {
  onClick: () => void;
  size?: ButtonSize;
  category?: ButtonCategory;
}) => {
  return (
    <ButtonV2
      onClick={onClick}
      category={category}
      size={size}
      text="Sign in"
    />
  );
};

const LoggedInText = () => {
  const {user} = usePrivy();
  const formattedConnectedAddress = formatToTinyAddress(
    user?.wallet?.address || ''
  );

  if (formattedConnectedAddress) {
    return <span>{formattedConnectedAddress}</span>;
  } else if (user?.email?.address) {
    return (
      <div className={classes.wrapper}>
        <EnvelopeIcon className={classes.socialIcons} />
        <span>{user.email.address}</span>
      </div>
    );
  } else if (user?.discord?.username) {
    return (
      <div className={classes.wrapper}>
        <DiscordIcon className={classes.socialIcons} />
        <span>{user.discord.username}</span>
      </div>
    );
  } else if (user?.twitter?.username) {
    return (
      <div className={classes.wrapper}>
        <TwitterIcon className={classes.socialIcons} />
        <span>{user.twitter.username}</span>
      </div>
    );
  } else {
    console.error('No social account found');
    return <span>Logged in</span>;
  }
};

const ShopLoggedInText = () => {
  const {user} = usePrivy();
  const addr = user?.wallet?.address;

  const [wrabTokenBalance, setWrabTokenBalance] = useState<number | null>(null);

  const refreshWalletDetails = async (connectedAddress: string) => {
    try {
      const wrab = await web3Client.getWRTokenBalance(connectedAddress);
      setWrabTokenBalance(Math.round(wrab * 100) / 100);
    } catch (err: any) {
      const msg = err.message || String(err);
      console.error('Failed to refresh wallet balances:', msg);
    }
  };

  useEffect(() => {
    if (addr) {
      refreshWalletDetails(addr);
    } else {
      setWrabTokenBalance(0);
    }
  }, [addr]);

  if (wrabTokenBalance == null) {
    return (
      <div className="flex items-center">
        <SpinnerIcon className="w-4 h-4" />
      </div>
    );
  }
  return <div className="flex items-center">{wrabTokenBalance} $WRAB</div>;
};

export const NavBarProfileButton = ({
  onClick,
  surface = 'default',
  size = 'sm',
  category = 'translucent',
}: {
  onClick?: () => void;
  size?: ButtonSize;
  surface?: Surface;
  category?: ButtonCategory;
}) => {
  const {login, logout, user} = usePrivy();
  const {email, loading, isTempSessionValid} = useTempSession();
  const {clearSessionToken} = useTempSessionActions();

  const onLogoutClicked = async () => {
    try {
      if (user) {
        await logout();
      } else if (isTempSessionValid) {
        clearSessionToken();
      }
      toaster.success('Signed out');
    } catch (e) {
      toaster.error('Error signing out');
    }
  };

  const onLoginButtonClicked = () => {
    onClick && onClick();
    login();
  };

  let navBarPrimaryButton = null;
  if (user) {
    navBarPrimaryButton = (
      <PopoverButtonV2
        className="sm:block"
        category={category}
        size={size}
        text={surface == 'shop' ? <ShopLoggedInText /> : <LoggedInText />}
      />
    );
  } else if (!loading && isTempSessionValid) {
    navBarPrimaryButton = (
      <PopoverButtonV2
        className="sm:block"
        category={category}
        size={size}
        text={
          <div className={classes.wrapper}>
            <EnvelopeIcon className={classes.socialIcons} />
            <span>{email}</span>
          </div>
        }
      />
    );
  } else {
    navBarPrimaryButton = (
      <NavBarLoginButton
        onClick={onLoginButtonClicked}
        size={size}
        category={category}
      />
    );
  }

  return (
    <Popover className="relative" as="div">
      {navBarPrimaryButton}
      <LoginDropdown onLogout={onLogoutClicked} />
    </Popover>
  );
};

const classes = {
  wrapper: 'flex items-center',
  socialIcons: 'mr-2 h-4 w-4',
};
