import {MouseEventHandler, ReactElement} from 'react';
import {Popover} from '@headlessui/react';

import {
  ButtonCategory,
  ButtonSize,
  getCategoryClassNames,
  getSizeClassNames,
} from '@/components/redesign/ButtonV2';

const PopoverButtonV2 = ({
  text,
  onClick,
  className = '',
  category = 'primary',
  size = 'sm',
}: {
  text: string | ReactElement | undefined;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  category?: ButtonCategory;
  size?: ButtonSize;
}) => {
  const buttonTypeClasses = getCategoryClassNames(category);
  const sizeClasses = getSizeClassNames(size);
  return (
    <Popover.Button
      className={`whitespace-nowrap rounded font-bold transition-all
      ${buttonTypeClasses} 
      ${sizeClasses} 
      ${className}`}
      onClick={onClick}
    >
      {text}
    </Popover.Button>
  );
};
export default PopoverButtonV2;
