import {ethers} from 'ethers';
import {formatLongText} from '@/utils/shared';

const isAddress = (maybeAddress: string) => {
  return (
    maybeAddress &&
    maybeAddress.substring(0, 2) == '0x' &&
    maybeAddress.length >= 3
  );
};

export const formatToChecksumAddress = (address: string) => {
  if (!isAddress(address)) {
    return '';
  }
  // Converts Ethereum address to check-sum capitalized address
  return ethers.utils.getAddress(address);
};

export const formatToShortAddress = (address: string) => {
  if (!isAddress(address)) {
    return '';
  }
  const formattedAddress = formatToChecksumAddress(address);
  return formatLongText(formattedAddress);
};

export const formatToTinyAddress = (address: string) => {
  if (!isAddress(address)) {
    return '';
  }

  const formattedAddress = formatToChecksumAddress(address);
  return `0x...${formattedAddress.substring(formattedAddress.length - 3)}`;
};
